import { useRoutes } from "react-router-dom";

// routes
/*import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';*/
import AuthenticationRoutes from "./AuthenticationRoutes";
import ConfContactClientRoute from "./ConfContactClientRoute";
import ConfGuardRoute from "./ConfGuardRoute";
import ExpRoute from "./ExpRoute";
import MainRoutes from "./MainRoutes";
import OportRoute from "./OportRoute";
import PropRoute from "./PropRoute";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    OportRoute,
    PropRoute,
    ExpRoute,
    ConfGuardRoute,
    ConfContactClientRoute,
    AuthenticationRoutes,
  ]);
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// project imports
import { enumPermissions } from "constants/permissions";
import useAuth from "hooks/useAuth";
import { checkPermissions } from "utils";
import LoadPage from "views/authentication/page";
import PageError from "views/error";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
// @ts-ignore
const AuthPropGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [load, setLoad] = useState(true);
  const [redirect403, setRedirect403] = useState(false);

  useEffect(() => {
    const permissions = [
      enumPermissions.viewProposal,
      enumPermissions.writeProposal,
    ];

    if (user) {
      if (
        !checkPermissions({ permissions, user }) ||
        (pathname.includes("/agregar") &&
          !checkPermissions({
            permissions: [enumPermissions.writeProposal],
            user,
          }))
      ) {
        setRedirect403(true);
      }
      setLoad(false);
    }
  }, [user, pathname]);

  return !load ? (
    redirect403 ? (
      <PageError
        codError={403}
        msg={"Ups... parece que no tienes permisos para acceder a esta pagina"}
      ></PageError>
    ) : (
      children
    )
  ) : (
    <LoadPage></LoadPage>
  );
};

AuthPropGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthPropGuard;

import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthConfGuard from "utils/route-guard/AuthConfGuard";
import AuthGuard from "utils/route-guard/AuthGuard";
import Permiso from "views/configuracion/permiso";

// configuracion routing
const Acciones = Loadable(lazy(() => import("views/configuracion/acciones")));

const Gerencia = Loadable(lazy(() => import("views/configuracion/gerencia")));
const TipoServicios = Loadable(
  lazy(() => import("views/configuracion/tipoServicios"))
);
const SectorEconomico = Loadable(
  lazy(() => import("views/configuracion/sectorEconomico"))
);
const Pais = Loadable(lazy(() => import("views/configuracion/pais")));

const Configuracion = [
  {
    path: "/configuracion/acciones/",
    element: <Acciones />,
  },
  {
    path: "/configuracion/gerencia/",
    element: <Gerencia />,
  },
  {
    path: "/configuracion/servicios/",
    element: <TipoServicios />,
  },
  {
    path: "/configuracion/sectores-economicos/",
    element: <SectorEconomico />,
  },
  {
    path: "/configuracion/paises/",
    element: <Pais />,
  },
  {
    path: "/configuracion/permisos/",
    element: <Permiso />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const ConfGuardRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <AuthConfGuard>
        <MainLayout />
      </AuthConfGuard>
    </AuthGuard>
  ),
  children: [...Configuracion],
};

export default ConfGuardRoute;

import { Chip, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function AutocompleteFormAsync({
  placeholder,
  getAsyncOption,
  multiple = false,
  handleChange,
  onClear,
  id,
  defaultValue,
  value,
  disabled = false,
  propTextField,
  ...props
}) {
  const [search, setSearch] = React.useState("");
  const [debouncedTerm, setDebouncedTerm] = React.useState(""); // Estado retrasado
  const [isFetching, setIsFetching] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const classes = {
    textfieldInput: {
      "& .MuiInputBaseInput.MuiDisabled": {
        WebkitTextFillColor: "#dce1e8",
      },
      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "#dce1e8", // Cambiar el color del borde cuando el TextField está deshabilitado
      },
    },
  };

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(search);
    }, 500); // Espera 500ms después de que el usuario deje de escribir

    return () => {
      clearTimeout(handler); // Cancela el timeout si el usuario sigue escribiendo
    };
  }, [search]);

  React.useEffect(() => {
    if (debouncedTerm || debouncedTerm === "") {
      searchItems(debouncedTerm);
    }
    //eslint-disable-next-line
  }, [debouncedTerm]);

  const searchItems = async (value) => {
    setIsFetching(true);
    let listOptions = await getAsyncOption(value);
    setOptions([...listOptions]);
    setIsFetching(false);
  };

  return (
    <Autocomplete
      value={value}
      id={id}
      multiple={multiple}
      disabled={disabled}
      sx={{}}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label || ""}
      options={options}
      loading={isFetching}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.value}
            label={option.label}
          />
        ));
      }}
      onChange={(event, newValue) => {
        if (!newValue) {
          setOptions([]);
          onClear();
          return;
        }
        handleChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ ...classes.textfieldInput }}
          onChange={async (event) => {
            let val = event.target.value;
            setSearch(val);
          }}
          placeholder={defaultValue ? defaultValue : placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...propTextField}
        />
      )}
      noOptionsText={placeholder}
      loadingText="Buscando ..."
      {...props}
    />
  );
}

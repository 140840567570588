import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthGuard from "utils/route-guard/AuthGuard";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
  ],
};

export default MainRoutes;

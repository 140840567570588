import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

function DeletePerfilModal({ open, setOpen, perfil, onSubmit }) {
  const [load, setLoad] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);
      await onSubmit();
      setLoad(false);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmar eliminación</DialogTitle>
      <DialogContent>
        {`Está seguro que desea el perfil ${perfil.nombre}`}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button
          onClick={handleSubmit}
          color="error"
          sx={{ width: "64px", height: "36.5px" }}
        >
          {!load ? "Sí" : <CircularProgress color="primary" size={14} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePerfilModal;

import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthExpGuard from "utils/route-guard/AuthExpGuard";
import AuthGuard from "utils/route-guard/AuthGuard";

// propuestas routing
const Experiencias = Loadable(
  lazy(() => import("views/experiencia/experiencias"))
);
const Experiencia = Loadable(
  lazy(() => import("views/experiencia/experiencia"))
);

// configuracion routing

const ExperienciaRoutes = [
  {
    path: "/experiencias",
    element: <Experiencias />,
  },
  {
    path: "/experiencia/agregar",
    element: <Experiencia />,
  },
  {
    path: "/experiencia/editar/:id",
    element: <Experiencia />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const ExpRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <AuthExpGuard>
        <MainLayout />
      </AuthExpGuard>
    </AuthGuard>
  ),
  children: [...ExperienciaRoutes],
};

export default ExpRoute;

import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { gridSpacing } from "store/constant";
import TextFieldFormik from "ui-component/forms/TextFieldFormik";
import ModalComponent from "ui-component/Modal";
import { iniValPermiso, valPermiso } from "../constants";

function PermisoModal({ open, handleCloseModal, permiso, handleSubmit }) {
  const formik = useFormik({
    initialValues: iniValPermiso,
    validationSchema: valPermiso,
    onSubmit: (value) => {
      handleSubmit({ ...value, isNew });
    },
  });

  const isNew = useMemo(() => {
    if (open) {
      return permiso.isNew;
    }
  }, [open, permiso]);

  const handleResetModal = () => {
    formik.resetForm();
  };

  const handleClose = () => {
    handleResetModal();
    handleCloseModal();
  };

  useEffect(() => {
    if (open && !isNew) {
      const keys = Object.keys(iniValPermiso);
      for (const key of keys) {
        permiso[key] && formik.setFieldValue(key, permiso[key]);
      }
    }
    if (!open) {
      handleResetModal();
    }
    //eslint-disable-next-line
  }, [open, permiso]);

  return (
    <ModalComponent
      openModal={open}
      setModal={handleClose}
      title={isNew ? "Crear permiso" : "Editar permiso"}
      onSubmit={() => {
        formik.handleSubmit();
        // console.log("Agregando...");
      }}
      maxWidth={"md"}
      fullWidth={true}
      labelBtnSubmit={isNew ? "Agregar" : "Editar"}
    >
      <Grid container sx={{ pt: 1 }} spacing={gridSpacing}>
        <Grid item xs={12}>
          <TextFieldFormik
            value={formik.values.nombre}
            handleChange={formik.handleChange}
            id={"nombre"}
            label={"Nombre"}
            formikError={{
              touched: formik.touched,
              errors: formik.errors,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            value={formik.values.descripcion}
            handleChange={formik.handleChange}
            id={"descripcion"}
            label={"Descripción"}
            multiline
            formikError={{
              touched: formik.touched,
              errors: formik.errors,
            }}
          />
        </Grid>
      </Grid>
    </ModalComponent>
  );
}

export default PermisoModal;

// third-party
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {};

const sliceOportunidad = createSlice({
  name: "oportunidad",
  initialState,
  reducers: {
    setValueOportunidad(state, action) {
      const { value } = action.payload;
      state.data = value;
    },
  },
});

export default sliceOportunidad.reducer;

export const { setValueOportunidad } = sliceOportunidad.actions;

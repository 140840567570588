// import { IconCalendarCheck, IconCalendarEvent, IconUserEdit } from "@tabler/icons-react";
import { array, object, string } from "yup";

export const iniValPermiso = {
  nombre: "",
  descripcion: "",
  isEdit: false,
  isLoadBtn: false,
};

export const iniValPerfil = {
  nombre: "",
  descripcion: "",
  permisos: [],
  isEdit: false,
  isLoadBtn: false,
};

export const iniValUsuarioPlataforma = {
  persona: null,
  profiles: [],
  gerencies: [],
  allViews: true,
};

export const valPermiso = object({
  nombre: string().required("Este campo es requerido."),
  descripcion: string().required("Este campo es requerido."),
});

export const valPerfil = object({
  persona: string().required("Este campo es requerido."),
});

export const valUsuarioPlataforma = object({
  persona: object()
    .shape({
      label: string().required("Este campo es requerido."),
    })
    .nonNullable("Este campo es requerido."),
  profiles: array()
    .of(
      object().shape({
        _id: string(),
      })
    )
    .min(1, "Es necesario al menos seleccionar un perfil")
    .required("Este campo es requerido."),
  gerencies: array().when(["allViews"], {
    is: (allViews) => !allViews,
    then: () =>
      array()
        .min(1, "Es necesario al menos tener una gerencia ingresada")
        .required("Este campo es requerido."),
    otherwise: () => array().notRequired(),
  }),
});

export const styleChip = (el) => ({
  backgroundColor: el.color,
  width: "100%",
  color: el.colorText,
  border: 1,
  borderColor: "grey !important",
});

"use client";

import { AssignmentInd, Person, VpnKey } from "@mui/icons-material";

import {
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";
import { useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import TabPanel from "ui-component/Tabs";
import PerfilesPlataforma from "./ListadoPerfiles";
import PermisosPlataforma from "./ListadoPermisos";
import UsuariosPlataforma from "./ListadoUsuarios";

function ConfUsuarios() {
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const [valueTab, setValueTab] = useState(0);
  const handleChange = (_, value) => {
    setValueTab(value);
  };

  const { user } = useAuth();

  return (
    <MainCard content={false}>
      <Grid container sx={{ p: 0 }}>
        <Grid item xs={1.55}>
          <CardContent sx={{ p: 0, pt: 2 }}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item xs={11}>
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      borderBottom: "none",
                    },
                    "& button": {
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[600]
                          : theme.palette.grey[600],
                      minHeight: "auto",
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "left",
                      alignItems: "center",
                      justifyContent: "start",
                      borderRadius: `${borderRadius}px`,
                    },
                    "& button.Mui-selected": {
                      color: theme.palette.primary.main,
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                    },
                    "& button > svg": {
                      marginBottom: "0px !important",
                      marginRight: 1.25,
                      height: 20,
                      width: 20,
                    },
                    "& button > div > span": {
                      display: "block",
                    },
                    "& > div > span": {
                      display: "none",
                    },
                  }}
                >
                  <Tab
                    key={1}
                    icon={<Person />}
                    label={
                      <Typography variant="subtitle1" color="inherit">
                        Usuarios
                      </Typography>
                    }
                    sx={{
                      mb: 0.5,
                    }}
                  />
                  <Tab
                    key={2}
                    icon={<AssignmentInd />}
                    label={
                      <Typography variant="subtitle1" color="inherit">
                        Perfiles
                      </Typography>
                    }
                    sx={{
                      mb: 0.5,
                    }}
                  />
                  {/* {user?.permisos?.includes("VerListadoPermisos") && ( */}
                  <Tab
                    key={3}
                    icon={<VpnKey />}
                    label={
                      <Typography variant="subtitle1" color="inherit">
                        Permisos
                      </Typography>
                    }
                    sx={{
                      mb: 0.5,
                    }}
                  />
                  {/* )} */}
                </Tabs>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={10.45}>
          <CardContent
            sx={{
              borderLeft: "1px solid",
              borderColor:
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : theme.palette.grey[200],
              height: "100%",
            }}
          >
            <TabPanel value={valueTab} index={0} key={`Permisos-${1}`}>
              <UsuariosPlataforma user={user} />
            </TabPanel>
            <TabPanel value={valueTab} index={1} key={`Permisos-${2}`}>
              <PerfilesPlataforma />
            </TabPanel>
            <TabPanel value={valueTab} index={2} key={`Permisos-${3}`}>
              <PermisosPlataforma />
            </TabPanel>
          </CardContent>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default ConfUsuarios;

import Axios from "axios";

import * as PERMISSIONS from "../../../constants/permissions";

import axiosServices from "utils/axios";
import { auth } from "../../../services/firebase";

export const tienePermisoParaVerContratosEncargado = (usuario) => {
  if (usuario?.permisos) {
    let isPermiso = usuario.permisos.find((item) => {
      return item.nombre === PERMISSIONS.VER_CONTRATOS_ENCARGADO;
    });

    if (typeof isPermiso !== "undefined") {
      return true;
    } else {
      return false;
    }
  } else {
    console.log(false);
    return false;
  }
};

export const tienePermisosParaVerTodosContrato = (usuario) => {
  if (usuario?.permisos) {
    let isPermiso = usuario.permisos.find((item) => {
      return item.nombre === PERMISSIONS.VER_CONTRATOS_CYD;
    });

    if (typeof isPermiso !== "undefined") {
      return true;
    } else {
      return false;
    }
  } else {
    console.log(false);
    return false;
  }
};
export const tienePermisosParaVerSolicitudes = (usuario) => {
  if (usuario?.permisos) {
    let isPermiso = usuario.permisos.find((item) => {
      return item.nombre === PERMISSIONS.VER_SOLICITUD_INGRESO;
    });

    if (typeof isPermiso !== "undefined") {
      return true;
    } else {
      return false;
    }
  } else {
    console.log(false);
    return false;
  }
};
export const tienePermisosParaVerCentroCosto = (usuario) => {
  if (usuario?.permisos) {
    let isPermiso = usuario.permisos.find((item) => {
      return item.nombre === PERMISSIONS.VER_CENTRO_COSTO;
    });

    if (typeof isPermiso !== "undefined") {
      return true;
    } else {
      return false;
    }
  } else {
    console.log(false);
    return false;
  }
};

export const tienePermisosParaVerContratosDeSuGerencia = (usuario) => {
  if (usuario?.permisos) {
    let isPermiso = usuario.permisos.find((item) => {
      return item.nombre === PERMISSIONS.VER_CONTRATOS_DE_GERENCIA;
    });

    if (typeof isPermiso !== "undefined") {
      return true;
    } else {
      return false;
    }
  } else {
    console.log(false);
    return false;
  }
};
export const ByID = async (id) => {
  return new Promise((resolve, reject) => {
    let base = "https://api-proyecto.cydocs.cl";
    Axios.get(`${base}/usuarios/${id}`, {})
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.log(error);
        resolve(error);
      });
  });
};

export const ByUsuarioID = async (usuario_id) => {
  return new Promise((resolve, reject) => {
    let base = "https://api-proyecto.cydocs.cl";
    Axios.get(`${base}/usuarios/uid/${usuario_id}`, {})
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.log(error);
        resolve(error);
      });
  });
};

/**
 * Obtener permisos
 * @param {String} rol nombre del rol del usuario
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getUsuario = async (rol, uid, cancelToken) => {
  const base = process.env.REACT_APP_DB_URL;
  let token = await auth.currentUser.getIdToken(true);
  const url = `${base}/roles/${rol}/permisos`;
  const promise = Axios.get(url, {
    params: {
      uid: uid,
    },
    headers: { Authorization: `Bearer ${token}` },
    canceltoken: cancelToken,
  });
  return promise;
};

export const getUserAPI = async (usuario_id) => {
  return new Promise((resolve, reject) => {
    let base = "https://api-usuarios.cydocs.cl";
    Axios.get(`${base}/personas/uid/${usuario_id}`, {})
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getUserAPIByRut = async (usuario_rut) => {
  return new Promise((resolve, reject) => {
    let base = "https://api-usuarios.cydocs.cl";
    Axios.get(`${base}/personas?run=${usuario_rut}`, {})
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getUserEspecial = async (nombre) => {
  return new Promise((resolve, reject) => {
    let base = "https://api-portafolio.cydocs.cl";
    Axios.get(`${base}/responsables`, {})
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const appendUsuarioPlataforma = async ({ body }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .post(`usuario`, body)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const editUsuarioPlataforma = async ({ id, body }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .put(`usuario/${id}`, body)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const deleteUsuarioPlataforma = async ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .delete(`usuario/${id}`)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useState } from "react";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axiosServices from "utils/axios";
import "./materialReactTable.css";
import { localizationMRT_ES, rowsPerPageOptions, sortQuery } from "./utils";

const findElementById = ({ array, id }) => {
  return array.find((row) => row?._id === id);
};

const initialPaginationValue = {
  pageIndex: 0,
  pageSize: 10,
};

const RemoteTable = ({
  idTable = "id",
  columns,
  url,
  extraParams = { filters: [] },
  badgeCount,
  handleOpenDocMasivosModal,
  FilterComponent,
  useMRTComponent = false,
  arrFilterBoolean = [],
  resetTable,
  setResetTable,
  initialState,
  ...tableProps
}) => {
  const dispatch = useDispatch();
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [selectRows, setSelectedRows] = useState([]);

  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowCountMax, setRowCountMax] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState(initialPaginationValue);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showGlobalFilter: false,
      density: "compact",
      columnVisibility: {
        "mrt-row-expand": false, // Oculta la columna Expandir
      },
      ...initialState,
    },
    enableColumnActions: false,
    localization: localizationMRT_ES,
    getRowId: (row) => {
      return row._id ?? Math.random() * 100;
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableRowSelection: false,
    enableExpanding: false,
    enableExpandAll: false,
    defaultColumn: {
      width: "auto",
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedRowIds,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      rowSelection: selectedRowIds,
    },
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 50 },
    muiTableFooterCellProps: {
      sx: {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        textAlign: "left",
        color: "#4B5565",
        backgroundColor: "#6975861A",
      },
    },
    muiTableContainerProps: {
      sx: {
        p: 0,
        maxWidth: "100%",
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [
        ...rowsPerPageOptions,
        {
          value: rowCountMax,
          label: "Todos",
        },
      ],
    },
    muiColumnActionsButtonProps: {
      style: {
        padding: "16px !important",
      },
    },
    muiTableHeadCellProps: ({ column }) =>
      column.id === "mrt-row-actions"
        ? {
            sx: {
              minWidth: "100px",
              maxWidth: "100%",
              paddingBottom: "0.4rem",
              paddingTop: "0.25rem",
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "center !important",
              },
            },
          }
        : {
            style: {
              paddingLeft: "16px",
              paddingRight: "16px",
            },
          },

    muiTableBodyCellProps: ({ column }) =>
      column.id === "mrt-row-actions"
        ? {
            sx: {
              minWidth: "100px",
              maxWidth: "100%",
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "center !important",
              },
            },
          }
        : {
            style: {
              paddingLeft: "16px",
              paddingRight: "16px",
            },
            align: "center",
          },

    muiTopToolbarProps: {
      sx: {
        "& .MuiBox-root": {
          padding: "0px !important",
        },
        padding: "16px !important",
      },
    },
    muiDetailPanelProps: {
      sx: {
        padding: 0,
      },
    },
    muiToolbarAlertBannerProps: {
      sx: {
        backgroundColor: "inherit",
        color: "inherit",
        "& .MuiStack-root": {
          padding: "0px",
          fontSize: "0.875rem",
          fontWeight: "400",
          lineHeight: "1.334em",
        },
        "& .MuiAlert-message": {
          padding: "0px",
          paddingBottom: "16px",
        },
      },
    },
    positionGlobalFilter: selectRows.length < 1 ? "left" : "right",
    positionActionsColumn: "last",
    renderTopToolbarCustomActions: ({ table }) => {
      return null;
    },
    ...tableProps,
  });

  useEffect(() => {
    const keys = Object.keys(selectedRowIds);
    const dataSelectRows = keys.map(
      (id) =>
        findElementById({ array: data, id }) ??
        findElementById({ array: selectRows, id })
    );
    setSelectedRows(dataSelectRows);
    //eslint-disable-next-line
  }, [selectedRowIds]);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  useEffect(() => {
    if (resetTable) {
      fetchData();
      setResetTable(false);
    }
    //eslint-disable-next-line
  }, [resetTable]);

  const getFilters = (filters) => {
    const objFilter = { ...filters };
    const arr = columnFilters.map((el) => {
      const object = {};
      const { value } = el.value;

      object[el.id] = value ?? el.value;
      return object;
    });
    for (const el of arr) {
      const key = Object.keys(el);
      if (typeof el[key] === "object" && !Object.keys(el[key]).length) {
        continue;
      }
      if (el[key]) objFilter[key] = el[key];
    }
    return objFilter;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const [orderBy] = sorting;
    const { filters, sort, ...extra } = extraParams;
    try {
      const query = {
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        search: globalFilter,
        filters: getFilters(filters),
        ...extra,
      };
      if (sort) {
        query["sort"] = `${sort}`;
      }
      if (orderBy) {
        query["sort"] = sortQuery({
          field: orderBy.id,
          orden: orderBy.desc ? "desc" : "asc",
        });
      }
      const { data } = await axiosServices.post(url, query);
      setData(data.docs);
      setRowCount(data.totalDocs);
      if (rowCountMax < data.totalDocs) setRowCountMax(data.totalDocs);
    } catch (error) {
      setIsError(true);
      showSnackBar(error.message, "alert", {
        color: "success",
      });
    } finally {
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  return <MaterialReactTable table={table} />;
};

export default RemoteTable;

import { Box, FormHelperText, TextField } from "@mui/material";
import { useMemo } from "react";

//Styles para quitar btn arrow en inputs de type numeric

function TextFieldFormik(props) {
  const {
    value,
    handleChange,
    type,
    id,
    label,
    formikError = { errors: [], touched: [] },
    placeholder = "Escribe...",
    handleBlur = () => {},
    ...resProps
  } = props;

  const classStyle = {
    number: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    default: {
      "& .MuiInputBaseInput.MuiDisabled": {
        WebkitTextFillColor: "#dce1e8",
      },
      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "#dce1e8", // Cambiar el color del borde cuando el TextField está deshabilitado
      },
    },
  };

  const checkErrorByID = useMemo(() => {
    return id.split(".").reduce((acc, curr) => {
      if (formikError.errors[curr]) {
        return formikError.errors[curr];
      } else {
        return acc ? acc[curr] : undefined;
      }
    }, {});
    //eslint-disable-next-line
  }, [formikError.errors]);

  const checkTouchedByID = useMemo(() => {
    return id.split(".").reduce((acc, curr) => {
      if (formikError.touched[curr]) {
        return formikError.touched[curr];
      } else {
        return acc ? acc[curr] : undefined;
      }
    }, {});
    //eslint-disable-next-line
  }, [formikError.touched]);

  return (
    <>
      <TextField
        type={type}
        sx={
          type === "number"
            ? { ...classStyle.number, display: "inline-flex !important" }
            : { ...classStyle.default, display: "inline-flex !important" }
        }
        id={id}
        name={id}
        label={label}
        value={value}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        error={checkTouchedByID && Boolean(checkErrorByID)}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={placeholder}
        style={{
          overflow: "visible",
        }}
        {...resProps}
      ></TextField>
      {checkTouchedByID && checkErrorByID && (
        <Box sx={{ mx: "14px" }}>
          <FormHelperText error id="standard-weight-helper-text-email-login">
            {" "}
            {checkErrorByID}{" "}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}

export default TextFieldFormik;

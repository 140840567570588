import axiosServices from "utils/axios";
const URL = "perfil";

export const getPerfiles = async () => {
  return new Promise((resolve, reject) => {
    axiosServices
      .get(`${URL}`)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const addPerfil = async ({ body }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .post(`${URL}`, body)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const editPerfil = async ({ body, id }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .put(`${URL}/${id}`, body)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const DeletePerfil = async ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosServices
      .delete(`${URL}/${id}`)
      .then((res) => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

import { enumPermissions } from "constants/permissions";

export const formatNumber = (value) => {
  if (value) {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "-";
  }
};

export const formatList = (array, key) => {
  return array.map((el) => {
    const obj = {
      value: el._id,
      label: el[key],
    };
    if (el.indentificador) {
      obj["id"] = el.indentificador;
      obj["value"] = el.indentificador;
    }
    return obj;
  });
};

export const formatOptions = (options, key) => {
  return options.map((el) => ({
    label: el[key],
    id: el._id,
  }));
};

export const getInfoPropByOportunidad = async ({ oportunidad }) => {
  const body = {
    isExtenMod: false,
    cliente: "",
    sociedad: "",
    gerencia: "",
    plazoEjecucion: "",
    fechaPresentacion: "",
    descripcion: "",
    tipoServicio: "",
  };
  const keys = Object.keys(body);
  for (const key of keys) {
    if (oportunidad[key]) {
      const { _id, id, value } = oportunidad[key];
      body[key] = _id || id || value || oportunidad[key];
    }
  }

  body["montoPropuesta"] = Number(oportunidad.montoOportunidad);
  body["oportunidad"] = formatOptions([oportunidad], "nombre")[0];

  const { respontableOportunidad } = oportunidad;
  let responsable = respontableOportunidad;
  if (respontableOportunidad._id) {
    const { contacto, _id, nombre, apellido_paterno, apellido_materno } =
      respontableOportunidad;
    responsable = {
      email: contacto.email,
      id: _id,
      label: `${nombre} ${apellido_paterno} ${apellido_materno}`,
    };
  }
  body["responsablePropuesta"] = responsable;

  return body;
};

export const checkPermissions = ({ permissions, user }) => {
  if (user && user.permissions.includes(enumPermissions.isAdmin)) {
    return true;
  }

  if (!permissions?.length || !user) {
    return false;
  }
  return validPermissions({ permissions, user });
};

const validPermissions = ({ permissions, user }) => {
  let havePermission = permissions.map((permission) =>
    user?.permissions?.includes(permission)
  );
  return havePermission?.some((value) => value);
};

import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// project imports
import { enumPermissions } from "constants/permissions";
import useAuth from "hooks/useAuth";
import { checkPermissions } from "utils";
import LoadPage from "views/authentication/page";
import PageError from "views/error";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
// @ts-ignore
const AuthConfClientContactGuard = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const [load, setLoad] = useState(true);
  const [redirect403, setRedirect403] = useState(false);

  useEffect(() => {
    const permissions = [
      enumPermissions.writeClientContact,
    ];
    if (user) {
      if (!checkPermissions({ permissions, user })) {
        setRedirect403(true);
      }
      setLoad(false);
    }
  }, [user]);

  return (
    <RenderComponent
      load={load}
      redirect403={redirect403}
      children={children}
    ></RenderComponent>
  );
};

const RenderComponent = ({ load, redirect403, children }) => {
  return !load ? (
    redirect403 ? (
      <PageError
        codError={403}
        msg={"Ups... parece que no tienes permisos para acceder a esta pagina"}
      ></PageError>
    ) : (
      children
    )
  ) : (
    <LoadPage></LoadPage>
  );
};

AuthConfClientContactGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthConfClientContactGuard;

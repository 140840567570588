import { Add } from "@mui/icons-material";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { MaterialReactTable } from "material-react-table";
import { useState } from "react";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { DeleteBtn } from "ui-component/Buttons";
import ModalComponent from "ui-component/Modal";
import GerenciaModal from "../Modals/GerenciaModal";
import { iniValAddContract, validationAddContract } from "./utils";

function ConfigPermissions({
  addContract,
  gerencies,
  allViews,
  setAllViews,
  isDisable = false,
  formikError = {
    error: "",
    touched: false,
  },
}) {
  const dispatch = useDispatch();
  const [isOpenModalAddContrato, setIsOpenModalAddContrato] = useState(false);
  const [data, setData] = useState(gerencies);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [item, setItem] = useState({});

  const formik = useFormik({
    initialValues: iniValAddContract,
    validationSchema: validationAddContract,
    onSubmit: async (value) => {
      addContract(
        value.typeValue.map((it) => {
          return {
            name: it.value,
            sigla: it.nombre,
            _id: it._id,
            typeItem: value.typeSelected,
          };
        })
      );

      formik.resetForm();
      setIsOpenModalAddContrato(false);

      showSnackBar(`Se ha agregado permisos con éxito`, "alert", {
        color: "success",
      });
    },
  });

  const columns = [
    {
      accessorKey: "nombre",
      header: "Nombre",
      grow: true,
    },
    {
      accessorKey: "tipo",
      header: "Tipo",
      grow: true,
    },
  ];

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  const handleOpenDelModal = (value) => {
    setItem(value);
    setOpenDelModal(true);
  };

  const findElement = (id) => {
    return data.find((el) => el._id === id);
  };

  const handleDelItem = () => {
    const element = findElement(item._id);
    if (!element) {
      return "";
    }
    const newArr = data.filter((el) => el._id !== item._id);
    setData(newArr);
    addContract(newArr);
    setOpenDelModal(false);
  };

  const handleAppendGerencia = (value) => {
    const element = findElement(value.valueSelected.value);

    if (element) {
      return "";
    }

    const newArr = [
      ...data,
      {
        tipo: value.typeSelected,
        nombre: value.valueSelected.nombre,
        _id: value.valueSelected.value,
      },
    ];
    addContract(newArr);
    setData(newArr);
  };

  const handleCheckRadio = (value) => {
    setAllViews(value);
  };

  return (
    <>
      <Grid container sx={{ pt: 1 }}>
        <Grid item xs={12}>
          {isDisable && <AlertFigma />}
          <Typography
            fontSize="10px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="16px"
            letterSpacing="0.3px"
            color="#9E9E9E"
            mt="12px"
          >
            Nivel de permisos de este usuario
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                checked={allViews}
                onClick={() => {
                  !isDisable && handleCheckRadio(true);
                }}
                disabled={isDisable}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#2196F3", // Cambia el color cuando el radio está seleccionado
                      },
                    }}
                  />
                }
                label="Todas las gerencias"
              />
              <FormControlLabel
                checked={!allViews}
                onClick={() => {
                  !isDisable && handleCheckRadio(false);
                }}
                disabled={isDisable}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#2196F3", // Cambia el color cuando el radio está seleccionado
                      },
                    }}
                  />
                }
                label="Algunas gerencias"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          {!allViews && (
            <>
              <MaterialReactTable
                columns={columns}
                data={data}
                enableRowActions={true}
                positionActionsColumn={"last"}
                renderRowActions={({ row }) => (
                  <DeleteBtn
                    onClick={() => handleOpenDelModal(row.original)}
                  ></DeleteBtn>
                )}
                renderToolbarInternalActions={() => (
                  <Tooltip title={"Agregar gerencia"}>
                    <IconButton
                      onClick={() => {
                        setIsOpenModalAddContrato(true);
                      }}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              ></MaterialReactTable>
              {formikError.touched && Boolean(formikError.error) && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {formikError.error}
                </FormHelperText>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <GerenciaModal
        open={isOpenModalAddContrato}
        setOpen={setIsOpenModalAddContrato}
        handleSubmitModal={handleAppendGerencia}
      ></GerenciaModal>
      <ModalComponent
        openModal={openDelModal}
        setModal={setOpenDelModal}
        title={"Eliminar gerencia"}
        onSubmit={handleDelItem}
        maxWidth={"sm"}
        fullWidth={true}
        labelBtnSubmit={"Guardar"}
      >
        <Typography>{`¿Seguro de eliminar la gerencia ${item.nombre} de la tabla?`}</Typography>
      </ModalComponent>
    </>
  );
}

const AlertFigma = () => {
  return (
    <Alert
      severity="info"
      sx={{
        backgroundColor: "#E3F2FD",
        mt: "24px",
        "& .MuiAlert-icon": {
          color: "#091351", // Cambia el color del icono aquí
        },
      }}
    >
      <Typography
        color="#091351"
        fontSize="12px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="20px"
        display="flex"
        flexDirection="row"
      >
        Debes configurar primero el usuario con al menos un perfil para
        asignarle un nivel de permisos
      </Typography>
    </Alert>
  );
};

export default ConfigPermissions;

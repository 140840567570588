import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

function ModalComponent({
  openModal,
  fullScreen = false,
  setModal,
  title = "Titulo",
  children,
  onSubmit = () => {},
  labelBtnSubmit = "Aceptar",
  loadBtn = false,
  childrenActions = (
    <Stack
      direction={"row"}
      justifyItems={"flex-end"}
      spacing={"12px"}
      sx={{
        pt: "24px",
        pb: "24px",
        pr: "24px",
      }}
    >
      <Button variant="outlined" onClick={() => setModal(false)}>
        Cancelar
      </Button>
      <Button
        variant="contained"
        onClick={() => onSubmit()}
        sx={{ height: "36.1px" }}
        disabled={loadBtn}
      >
        {loadBtn ? (
          <CircularProgress
            color="primary"
            size={14}
            sx={{ color: loadBtn && "grey" }}
          />
        ) : (
          labelBtnSubmit
        )}
      </Button>
    </Stack>
  ),
  draggable = false,
  sxDialogTitle,
  colorBtnDialogTitle = "primary",
  sxBtnDialogTitle,
  propsDialog = {
    sx: {
      p: 0,
      px: "24px",
    },
  },
  ...props
}) {
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      // You can check the reason here
      setModal(false);
    }
    // setModal(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={openModal}
      sx={{
        "& .MuiPaper-root": {
          pb: 0,
        },
      }}
      {...props}
    >
      <DialogTitle
        style={{ cursor: draggable && "move" }}
        id="draggable-dialog-title"
        sx={{ ...sxDialogTitle }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {typeof title === "string" ? (
            <Typography variant="h3">{title}</Typography>
          ) : (
            title
          )}
          <IconButton
            aria-label="delete"
            size="small"
            color={colorBtnDialogTitle}
            onClick={handleClose}
            sx={{ ...sxBtnDialogTitle, p: 0 }}
          >
            <CloseIcon sx={{ mr: "-5px" }}></CloseIcon>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }} {...propsDialog}>
        {children}
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>{childrenActions}</DialogActions>
    </Dialog>
  );
}

export default ModalComponent;

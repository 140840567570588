// material-ui
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function PageError({ codError, msg }) {
  return (
    <ErrorCard>
      <CardContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h1">Error {codError}</Typography>
          </Grid>
          <Grid item>
            <Typography>{msg}</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" size="large" href={"/"}>
              Inicio
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  );
}

export default PageError;

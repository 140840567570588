import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { AutocompletePersonaAsync } from "ui-component/AutocompletePersonaAsync";
import ModalComponent from "ui-component/Modal";
import { iniValUsuarioPlataforma, valUsuarioPlataforma } from "../constants";
import ConfigPermissions from "./ConfigPermissions";

export const formatPerfilList = (arr) => {
  return (
    arr.map((el) => ({
      value: el._id,
      nombre: el.nombre,
      label: `${el.nombre} - ${el.descripcion}`,
    })) ?? []
  );
};

function UsuarioModal({
  open,
  user,
  handleCloseModal,
  handleSubmit,
  perfiles = [],
}) {
  const [value, setValue] = useState(0);

  const tabs = ["Configurar usuario", "Configurar nivel de permisos"];

  const formik = useFormik({
    initialValues: iniValUsuarioPlataforma,
    validationSchema: valUsuarioPlataforma,
    onSubmit: async (value) => {
      await handleSubmit({ ...value, isNew });
    },
  });

  const isNew = useMemo(() => {
    return open && !Boolean(user);
  }, [open, user]);

  useEffect(() => {
    if (open && !isNew) {
      const { gerencias_ref, perfiles_ref, visibilidad_completa, persona_ref } =
        user;

      const { nombre, apellido_materno, apellido_paterno, contacto } =
        persona_ref;
      formik.setFieldValue("persona", {
        label: `${nombre} ${apellido_materno} ${apellido_paterno} <${contacto.email}>`,
        value: persona_ref._id,
      });

      formik.setFieldValue(
        "profiles",
        perfiles_ref.map((el) => {
          const { _id, nombre, descripcion } = el;
          return { value: _id, label: `${nombre} - ${descripcion}` };
        })
      );

      formik.setFieldValue("allViews", visibilidad_completa);

      formik.setFieldValue(
        "gerencies",
        gerencias_ref.map((el) => {
          const { _id, nombre } = el;
          return {
            _id,
            nombre,
            tipo: "Gerencia",
          };
        })
      );
    }
    //eslint-disable-next-line
  }, [open, isNew, user]);

  const handleResetModal = () => {
    formik.resetForm();
  };

  const handleClose = () => {
    handleResetModal();
    handleCloseModal();
  };

  useEffect(() => {
    if (open) {
      setValue(0);
    }
  }, [open]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const AlertFigma = () => {
    return (
      <Alert
        severity="warning"
        sx={{
          backgroundColor: "#FFF8E1",
          mt: "24px",
        }}
      >
        <Typography
          color="#662102"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="20px"
          display="flex"
          flexDirection="row"
        >
          El usuario ya existe en la plataforma, por favor edita este usuario en
          la tabla para agregarle permisos o selecciona otro usuario
        </Typography>
      </Alert>
    );
  };

  return (
    <ModalComponent
      openModal={open}
      setModal={handleClose}
      title={
        !isNew
          ? "Editar Usuario de la Plataforma"
          : "Agregar Usuario a la Plataforma"
      }
      onSubmit={() => {
        if (formik.values.userExist) return;
        formik.handleSubmit();
      }}
      maxWidth={"md"}
      fullWidth={true}
      labelBtnSubmit={!isNew ? "Editar" : "Guardar"}
    >
      <Tabs
        value={value}
        onChange={async (event, newValue) => {
          setValue(newValue);
        }}
        aria-label="basic tabs example"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#8F1212",
          },
        }}
      >
        {tabs.map((it, idx) => {
          return (
            <Tab
              key={`${idx}_tab`}
              label={it}
              {...a11yProps(idx)}
              sx={{
                "&.Mui-selected": { color: "#8F1212" },
                color: "#4B5565",
                padding: "12px 4px",
              }}
            />
          );
        })}
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <Grid container sx={{ pt: 1 }} spacing={"20px"}>
          <Grid item xs={12}>
            {formik.values.userExist && <AlertFigma />}
            <FormControl fullWidth variant="outlined" sx={{ mt: "24px" }}>
              <InputLabel
                htmlFor="persona.label"
                shrink={true}
                sx={{ bgcolor: "white", pl: "3px", pr: "4px" }}
                error={formik.touched.persona && Boolean(formik.errors.persona)}
              >
                Usuario
              </InputLabel>
              <AutocompletePersonaAsync
                id="persona.label"
                disabled={!isNew}
                placeholder="Escribe y selecciona un usuario"
                value={formik.values.persona}
                getOptionLabel={(option) => option.label || ""}
                setValue={async (value) => {
                  formik.setFieldValue("persona", value);
                }}
                lg={12}
                propsTextField={{
                  error:
                    formik.touched.persona && Boolean(formik.errors.persona),
                  helperText:
                    formik.touched.persona &&
                    Boolean(formik.errors.persona) &&
                    formik.errors.persona,
                }}
              ></AutocompletePersonaAsync>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              id="profiles"
              multiple
              value={formik.values.profiles}
              options={perfiles}
              onChange={(_, values) => {
                formik.setFieldValue("profiles", values);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.value}
                    label={option.label}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Perfiles"}
                  placeholder="Selecciona uno o más perfiles"
                  error={
                    formik.touched.profiles && Boolean(formik.errors.profiles)
                  }
                  helperText={
                    formik.touched.profiles &&
                    Boolean(formik.errors.profiles) &&
                    formik.errors.profiles
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option.value === value?.value || option.value === value;
              }}
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ConfigPermissions
          isDisable={
            !Boolean(formik.values.persona) ||
            formik.values.profiles.length === 0
          }
          addContract={(val) => {
            formik.setFieldValue("gerencies", val);
          }}
          gerencies={formik.values.gerencies}
          allViews={formik.values.allViews}
          setAllViews={(value) => formik.setFieldValue("allViews", value)}
          formikError={{
            error: formik.errors.gerencies,
            touched: formik.touched.gerencies,
          }}
        />
      </CustomTabPanel>
    </ModalComponent>
  );
}

export default UsuarioModal;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthConfClientContactGuard from "utils/route-guard/AuthConfClientContactGuard";
import AuthGuard from "utils/route-guard/AuthGuard";

const Empresa = Loadable(lazy(() => import("views/configuracion/cliente")));
const Contacto = Loadable(lazy(() => import("views/configuracion/contacto")));

const ConfContactClient = [
  {
    path: "/configuracion/empresa/",
    element: <Empresa />,
  },
  {
    path: "/configuracion/contacto/",
    element: <Contacto />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const ConfContactClientRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <AuthConfClientContactGuard>
        <MainLayout />
      </AuthConfClientContactGuard>
    </AuthGuard>
  ),
  children: [...ConfContactClient],
};

export default ConfContactClientRoute;

import { array, object, string } from "yup";

export const iniValAddContract = {
  typeSelected: "",
  typeValue: null,
};

export const validationAddContract = object({
  typeValue: array().of(
    object().shape({
      _id: string().required('Campo requerido requerido'),
      nombre: string().required('Campo requerido requerido'),
      value: string().required('Campo requerido requerido'),
      typeItem: string().required('Campo requerido requerido'),
    })
  ).required('Campo requerido'),
  typeSelected: string().required('Campo requerido'),
});

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Autocomplete,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import axiosServices from "utils/axios";
import InputLabel from "./extended/Form/InputLabel";

const formatPersonaAutocomplete = (data) => {
  const result = data.map((el) => {
    const { _id, nombreCompleto, email } = el;
    return {
      id: _id,
      label: `${nombreCompleto} <${email}>`,
      email,
    };
  });
  return result;
};

export const AutocompletePersonaAsync = ({
  label,
  value,
  setValue,
  id,
  touched,
  errors,
  errorsMsg,
  xs = 12,
  lg = 6,
  isTooltip,
  msgTooltip = "msg tooltip",
  placeholder = "",
  propsTextField,
  ...props
}) => {
  const [openResCliente, setOpenResCliente] = useState(false);
  const [loadAutocomplete, setLoadAutocomplete] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValueSearch, setInputValueSearch] = useState("");

  useEffect(() => {
    inputValueSearch !== "" && setLoadAutocomplete(true);
    //al pasar 0.3 segundos después de que el usuario deje de escribir, se ejecuta la petición al backend de persona
    const timer = setTimeout(() => {
      if (inputValueSearch !== "") {
        getPersonaByFilter(inputValueSearch);
      }
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueSearch]);

  //fetch para obtener personas
  const getPersonaByFilter = (filter) => {
    const body = {
      filter: filter,
    };
    setLoadAutocomplete(true);
    axiosServices
      .post("/personas/findByFilter", body)
      .then(({ data }) => {
        if (data.length === 0) {
          setOptions([]);
          return;
        }
        const result = formatPersonaAutocomplete(data);
        // console.log("data", data);
        setOptions([...result]);
      })
      .finally(() => setLoadAutocomplete(false));
  };

  return (
    <Grid item xs={xs} lg={lg}>
      <Grid container>
        <Grid item>
          <InputLabel>{label}</InputLabel>
        </Grid>
        {isTooltip && (
          <Grid item sx={{ pl: 0.5, mt: -0.25 }}>
            <Tooltip title={msgTooltip}>
              <HelpOutlineIcon></HelpOutlineIcon>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={xs}>
          <Autocomplete
            id={id}
            freeSolo
            noOptionsText={
              options.length && inputValueSearch === ""
                ? "Escriba el nombre para buscar"
                : "Sin resultados"
            }
            value={value}
            options={options}
            open={openResCliente}
            onOpen={() => {
              setOpenResCliente(true);
            }}
            onClose={() => {
              setOpenResCliente(false);
            }}
            onInputChange={(_, value) => {
              setInputValueSearch(value);
            }}
            onChange={(_, value) => {
              setValue(value);
            }}
            loading={loadAutocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder={placeholder}
                error={touched && Boolean(errors)}
                {...propsTextField}
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.id === value.id || value === null
            }
            {...props}
          ></Autocomplete>
          {touched && errors && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {" "}
              {errorsMsg}{" "}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { gridSpacing } from "store/constant";
import TextFieldFormik from "ui-component/forms/TextFieldFormik";
import ModalComponent from "ui-component/Modal";
import { iniValPerfil } from "../constants";

function PerfilModal({
  open,
  handleCloseModal,
  perfil,
  handleSubmit,
  permisos,
}) {
  const formik = useFormik({
    initialValues: iniValPerfil,
    onSubmit: (value) => {
      handleSubmit({ ...value, isNew });
    },
  });

  const isNew = useMemo(() => {
    if (open) {
      return perfil.isNew;
    }
  }, [open, perfil]);

  const handleResetModal = () => {
    formik.resetForm();
  };

  const handleClose = () => {
    handleResetModal();
    handleCloseModal();
  };

  useEffect(() => {
    if (open && !isNew) {
      const keys = Object.keys(iniValPerfil);
      for (const key of keys) {
        perfil[key] && formik.setFieldValue(key, perfil[key]);
      }
    }
    if (!open) {
      handleResetModal();
    }
    //eslint-disable-next-line
  }, [open, perfil]);

  return (
    <ModalComponent
      openModal={open}
      setModal={handleClose}
      title={isNew ? "Crear perfil" : "Editar perfil"}
      onSubmit={formik.handleSubmit}
      maxWidth={"md"}
      fullWidth={true}
      labelBtnSubmit={isNew ? "Agregar" : "Editar"}
    >
      <Grid container sx={{ pt: 1 }} spacing={gridSpacing}>
        <Grid item xs={12}>
          <TextFieldFormik
            value={formik.values.nombre}
            handleChange={formik.handleChange}
            id={"nombre"}
            label={"Nombre"}
            formikError={{
              touched: formik.touched,
              errors: formik.errors,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldFormik
            value={formik.values.descripcion}
            handleChange={formik.handleChange}
            id={"descripcion"}
            label={"Descripción"}
            multiline
            formikError={{
              touched: formik.touched,
              errors: formik.errors,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={permisos ? permisos : []}
            value={
              permisos
                ? Array.from(permisos).filter((p) =>
                    formik.values.permisos.includes(p._id)
                  )
                : []
            }
            getOptionLabel={(permiso) =>
              `${permiso.nombre} - ${permiso.descripcion}`
            }
            onChange={(event, value) => {
              formik.setFieldValue(
                "permisos",
                value.map((v) => v._id)
              );
            }}
            renderInput={(params) => (
              <TextField label="Permisos" variant="outlined" {...params} />
            )}
            // eslint-disable-next-line react/jsx-key
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option.nombre} {...getTagProps({ index })} />
              ))
            }
          />
        </Grid>
      </Grid>
    </ModalComponent>
  );
}

export default PerfilModal;

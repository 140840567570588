import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useState } from "react";

// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import accountReducer from "store/accountReducer";
import { LOGIN, LOGOUT } from "store/actions";
import axiosServices, { setTokenBearer } from "utils/axios";
import LoadPage from "views/authentication/page";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const [appLoading, setAppLoading] = useState(false);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          localStorage.setItem("token", token);
          await getInfoUser(user);
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(
    () =>
      firebase.auth().onIdTokenChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          const oldToken = localStorage.getItem("token");
          if (!token === oldToken) {
            localStorage.setItem("token", token);
            await getInfoUser(user);
          }
        }
      }),
    [dispatch]
  );

  const getInfoUser = async (user) => {
    try {
      setAppLoading(true)
      const { data } = await axiosServices.get(`/personas/uid/${user.uid}`);
      const {
        _id,
        gerencia,
        perfil,
        permisos,
        gerencias,
        token,
        visibilidad_completa,
      } = data ?? {
        gerencias: [],
      };
      localStorage.setItem("tokenBackend", token);
      setTokenBearer();
      setAppLoading(false);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            id: user.uid,
            email: user.email,
            name: user.displayName || "John Doe",
            photoURL: user.photoURL,
            _id,
            gerencia: gerencia._id,
            perfil,
            gerencies: gerencias,
            permissions: permisos,
            visibilidad_completa,
          },
        },
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const logout = () => firebase.auth().signOut();

  if(appLoading){
    console.log('loading');
    return <LoadPage></LoadPage>
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;

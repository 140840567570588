import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthGuard from "utils/route-guard/AuthGuard";
import AuthOportGuard from "utils/route-guard/AuthOportGuard";

// oportunidades routing
const OportunidadIndicadores = Loadable(
  lazy(() => import("views/oportunidad/indicadores"))
);
const Oportunidad = Loadable(
  lazy(() => import("views/oportunidad/oportunidad"))
);
const Oportunidades = Loadable(
  lazy(() => import("views/oportunidad/oportunidades"))
);

const OportunidadRoutes = [
  {
    path: "/oportunidad/indicadores",
    element: <OportunidadIndicadores />,
  },
  {
    path: "/oportunidades",
    element: <Oportunidades />,
  },
  {
    path: "/oportunidad/agregar",
    element: <Oportunidad />,
  },
  {
    path: "/oportunidad/editar/:id",
    element: <Oportunidad />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const OportRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <AuthOportGuard>
        <MainLayout />
      </AuthOportGuard>
    </AuthGuard>
  ),
  children: [...OportunidadRoutes],
};

export default OportRoute;

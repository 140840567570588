// assets
// import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import { InsertChart, MiscellaneousServices } from "@mui/icons-material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { enumPermissions } from "constants/permissions";
// constant
// const icons = {
//   IconDashboard,
//   IconDeviceAnalytics
// };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "menu",
  title: "Menú",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: InsertChart,
      breadcrumbs: true,
    },
    {
      id: "oportunidad",
      title: "Oportunidades",
      type: "collapse",
      icon: TipsAndUpdatesIcon,
      breadcrumbs: true,
      url: "/oportunidades",
      permissions: [
        enumPermissions.viewOpportunity,
        enumPermissions.writeOpportunity,
      ],
      children: [
        {
          id: "post1",
          title: "Oportunidades",
          type: "item",
          url: "/oportunidades",
          permissions: [
            enumPermissions.viewOpportunity,
            enumPermissions.writeOpportunity,
          ],
        },
        {
          id: "post2",
          title: "Agregar oportunidad",
          type: "item",
          url: "/oportunidad/agregar/",
          permissions: [enumPermissions.writeOpportunity],
        },
        {
          id: "post3",
          title: "Editar oportunidad",
          type: "hidden",
          url: "/oportunidad/editar/:id",
          permissions: [enumPermissions.writeOpportunity],
        },
      ],
    },
    {
      id: "propuesta",
      title: "Propuestas",
      type: "collapse",
      url: "/propuestas",
      icon: RequestQuoteIcon,
      breadcrumbs: false,
      permissions: [
        enumPermissions.viewProposal,
        enumPermissions.writeProposal,
      ],

      children: [
        {
          id: "ver_propuestas",
          title: "Ver propuestas",
          type: "item",
          url: "/propuestas",
          permissions: [
            enumPermissions.viewProposal,
            enumPermissions.writeProposal,
          ],
        },
        {
          id: "add_propuesta",
          title: "Agregar propuesta",
          type: "item",
          url: "/propuesta/agregar",
          permissions: [enumPermissions.writeProposal],
        },
        {
          id: "post3",
          title: "Editar propuesta",
          type: "hidden",
          url: "/propuesta/editar/:id",
          permissions: [enumPermissions.writeProposal],
        },
      ],
    },
    {
      id: "experiencia",
      title: "Experiencia",
      type: "collapse",
      url: "/experiencias",
      icon: WorkspacePremiumIcon,
      breadcrumbs: false,
      permissions: ["isAdmin", "viewExperiencia", "writeExperiencia"],
      children: [
        {
          id: "ver_experiencia",
          title: "Ver experiencia",
          type: "item",
          url: "/experiencias",
          permissions: ["isAdmin", "viewExperiencia", "writeExperiencia"],
        },
        {
          id: "add_experiencia",
          title: "Agregar experiencia",
          type: "item",
          url: "/experiencia/agregar",
          permissions: ["isAdmin", "writeExperiencia"],
        },
        {
          id: "edit_experiencia",
          title: "Editar experiencia",
          type: "hidden",
          url: "/experiencia/editar/:id",
          permissions: ["isAdmin", "writeExperiencia"],
        },
      ],
    },
    {
      id: "configuracion",
      title: "Configuración",
      url: "/",
      icon: MiscellaneousServices,
      breadcrumbs: false,
      type: "collapse",
      permissions: [enumPermissions.writeClientContact],
      children: [
        {
          id: "acciones",
          title: "Acciones",
          type: "item",
          url: "/configuracion/acciones/",
          permissions: [],
        },
        {
          id: "clientes",
          title: "Clientes y Empresas",
          type: "item",
          url: "/configuracion/empresa/",
          permissions: [enumPermissions.writeClientContact],
        },
        {
          id: "contactos",
          title: "Contactos cliente",
          type: "item",
          url: "/configuracion/contacto/",
          permissions: [enumPermissions.writeClientContact],
        },
        {
          id: "gerencias",
          title: "Gerencias",
          type: "item",
          url: "/configuracion/gerencia/",
          permissions: [],
        },
        {
          id: "servicios",
          title: "Tipos de Servicios",
          type: "item",
          url: "/configuracion/servicios/",
          permissions: [],
        },
        {
          id: "rubros",
          title: "Sectores Económicos",
          type: "item",
          url: "/configuracion/sectores-economicos/",
          permissions: [],
        },
        {
          id: "paises",
          title: "Paises",
          type: "item",
          url: "/configuracion/paises/",
          permissions: [],
        },
        {
          id: "permisos",
          title: "Permisos del sitio",
          type: "item",
          url: "/configuracion/permisos/",
          permissions: [],
        },
      ],
    },
  ],
};

export default dashboard;

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

function SelectForm({
  value = "",
  id,
  label,
  handleChange = null,
  MenuItems,
  sxSelect,
  formikError = { touched: {}, errors: {} },
  placeholder = "Seleccionar una opción",
  placeHolder,
  size = "small",
  sxForm,
  ...restProps
}) {
  return (
    <FormControl
      fullWidth
      error={formikError.touched[id] && Boolean(formikError.errors[id])}
      size={size}
      sx={{
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: "#dce1e8", // Cambiar el color del borde cuando el TextField está deshabilitado
        },
        display: "inline-flex !important",
        ...sxForm,
      }}
    >
      <InputLabel id="demo-simple-select-label" shrink={true}>
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        notched
        value={value}
        label={label}
        id={id}
        displayEmpty
        name={id}
        onChange={handleChange}
        sx={{
          "& .MuiSelect-select .notranslate::after": placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
                ...sxSelect,
              }
            : { ...sxSelect },
        }}
        endAdornment={
          value !== ""
            ? restProps.multiple && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      // if (onMenuClick) {
                      //   onMenuClick({ item: { value: "" }, value: "" });
                      // } else {
                      // }
                      handleChange({  
                        currentTarget: {
                          id,
                          value: "",
                        },
                      });
                    }}
                    size="small"
                    sx={{ mr: 4 }}
                  ></IconButton>
                </InputAdornment>
              )
            : null
        }
        {...restProps}
      >
        {placeHolder && (
          <MenuItem value="" disabled sx={{ color: "gray", display: "none" }}>
            {placeHolder}
          </MenuItem>
        )}
        {MenuItems.map(({ value, label, sx, children, disabled, ...props },idx) =>
          disabled ? (
            <Tooltip
              title="Esta categoría no tiene documentos vinculados"
              placement="bottom-start"
              key={idx}
            >
              <span>
                <MenuItem
                  value={value}
                  key={`${value}-${label}`}
                  sx={{ ...sx }}
                  disabled={disabled}
                  style={{ pointerEvents: "auto" }}
                  {...props}
                >
                  {label}
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              value={value}
              key={`${value}-${label}`}
              sx={{ ...sx }}
              disabled={disabled}
              {...props}
            >
              {children ? children : label}
            </MenuItem>
          )
        )}
      </Select>
      {formikError.touched[id] && formikError.errors[id] && (
        <FormHelperText error id={`error-selects-${label.toLowerCase()}`}>
          {" "}
          {formikError.errors[id]}{" "}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectForm;

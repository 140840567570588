"use client";

import { Add } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import {
  addPermiso,
  DeletePermiso,
  editPermiso,
} from "services/database/permiso";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { DeleteBtn, EditBtn } from "ui-component/Buttons";
import RemoteTable from "ui-component/Table/RemoteTable";
import DeletePermisoModal from "./Modals/DeletePermiso";
import PermisoModal from "./Modals/PermisoModal";

function PermisosPlataforma(user) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [permiso, setPermiso] = useState({});
  const [resetTable, setResetTable] = useState([]);

  const columns = [
    {
      accessorKey: "nombre",
      header: "Nombre",
      size: 50,
      Cell: ({ row }) => (
        <Tooltip title={row.original.nombre}>
          <Typography noWrap>{row.original.nombre}</Typography>
        </Tooltip>
      ),
    },
    {
      accessorKey: "descripcion",
      header: "Descripcion",
      Cell: ({ row }) => (
        <Tooltip title={row.original.descripcion}>
          <Typography noWrap>{row.original.descripcion}</Typography>
        </Tooltip>
      ),
    },
  ];

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  const handleOpenModal = (permiso) => {
    setPermiso(permiso);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSubmitPermiso = async (values) => {
    try {
      const { isNew, ...body } = values;
      if (isNew) {
        await addPermiso({ body });
      } else {
        await editPermiso({ body, id: permiso._id });
      }
      handleCloseModal();
      showSnackBar("Permiso agregado con exito.", "alert", {
        color: "success",
      });
      setResetTable(true);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    } finally {
      setPermiso({});
    }
  };

  const handleOpenDel = (permiso) => {
    setPermiso(permiso);
    setOpenDelModal(true);
  };

  const handleDelete = async () => {
    try {
      await DeletePermiso({ id: permiso._id });
      showSnackBar("Permiso eliminado con exito.", "alert", {
        color: "success",
      });
      setOpenDelModal(false);
      setResetTable(true);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    } finally {
      setPermiso({});
    }
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <RemoteTable
        url={"permisos/pagination"}
        columns={columns}
        resetTable={resetTable}
        setResetTable={setResetTable}
        enableFilters={true}
        initialState={{
          showGlobalFilter: true,
          density: "compact",
        }}
        positionGlobalFilter={"left"}
        enableRowActions={true}
        renderRowActions={({ row }) => (
          <>
            <EditBtn onClick={() => handleOpenModal(row.original)}></EditBtn>
            <DeleteBtn onClick={() => handleOpenDel(row.original)}></DeleteBtn>
          </>
        )}
        renderToolbarInternalActions={() => (
          <Tooltip title={"Agregar permiso"}>
            <IconButton
              onClick={() => {
                handleOpenModal({ isNew: true });
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        muiTableBodyCellProps={({ column }) =>
          column.id === "mrt-row-actions"
            ? {
                sx: {
                  minWidth: "100px",
                  maxWidth: "100%",
                },
                align: 'center'
              }
            : {
                style: {
                  paddingLeft: "16px",
                  paddingRight: "16px",
                },
              }
        }
      ></RemoteTable>
      <DeletePermisoModal
        open={openDelModal}
        setOpen={setOpenDelModal}
        permiso={permiso}
        onSubmit={handleDelete}
      ></DeletePermisoModal>
      <PermisoModal
        open={openModal}
        handleCloseModal={handleCloseModal}
        permiso={permiso}
        handleSubmit={handleSubmitPermiso}
      ></PermisoModal>
    </div>
  );
}

export default PermisosPlataforma;

"use client";

import { Add } from "@mui/icons-material";
import {
  Chip,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getPerfiles } from "services/database/perfil";
import {
  appendUsuarioPlataforma,
  deleteUsuarioPlataforma,
  editUsuarioPlataforma,
} from "services/database/usuario";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { DeleteBtn, EditBtn } from "ui-component/Buttons";
import ModalComponent from "ui-component/Modal";
import RemoteTable from "ui-component/Table/RemoteTable";
import UsuarioModal from "./UsuarioModal";

const PopoverItemColumn = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const text = useMemo(() => {
    return items.length === 1 ? "gerencia" : "gerencias";
  }, [items]);

  return (
    <>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {`${items.length} ${text}`}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {items.map((item) => (
          <Typography
            sx={{ p: 1 }}
            key={item._id}
          >{`${item.nombre}`}</Typography>
        ))}
      </Popover>
    </>
  );
};

function UsuariosPlataforma() {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [perfiles, setPerfiles] = useState([]);
  const [resetTable, setResetTable] = useState([]);

  const [user, setUser] = useState(null);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [loadBtn, setLoadBtn] = useState(false);

  const columns = [
    {
      accessorKey: "nombre",
      header: "Nombre",
      grow: true,

      Cell: ({ row }) => {
        const { nombre, apellido_materno, apellido_paterno } =
          row.original.persona_ref;
        return (
          <Typography>{`${nombre} ${apellido_paterno} ${apellido_materno}`}</Typography>
        );
      },
    },
    {
      accessorKey: "perfiles",
      header: "Perfiles",
      grow: true,
      Cell: ({ row }) => (
        <div>
          {row.original.perfiles_ref?.map((perfil, index) => (
            <Chip key={index} label={perfil.nombre} style={{ margin: 2 }} />
          ))}
        </div>
      ),
    },
    {
      accessorKey: "visibilidad_completa",
      header: "Visibilidad",
      grow: true,
      align: "left",
      Cell: ({ row }) => {
        if (row.original.visibilidad_completa) {
          return (
            <Typography component="div" align="left">
              Todos los contratos
            </Typography>
          );
        }

        return (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <PopoverItemColumn items={row.original.gerencias_ref} />
          </Stack>
        );
      },
    },
  ];

  const nombrePersona = useMemo(() => {
    if (!user?.persona_ref) {
      return "";
    }
    const { nombre, apellido_materno, apellido_paterno } = user.persona_ref;
    return `${nombre} ${apellido_materno} ${apellido_paterno}`;
  }, [user]);

  useEffect(() => {
    const handleGetPerfiles = async () => {
      const data = await getPerfiles();
      setPerfiles(
        data.map((perfil) => {
          const { _id, nombre, descripcion } = perfil;
          return { value: _id, label: `${nombre} - ${descripcion}` };
        })
      );
    };
    handleGetPerfiles();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddUser = () => {
    setOpenModal(true);
  };

  const handleSubmitUser = async ({
    persona,
    gerencies,
    profiles,
    allViews,
    isNew,
  }) => {
    const body = {
      allViews,
    };
    body["gerencies"] = gerencies.map((el) => el._id) ?? [];
    body["profiles"] = profiles.map((el) => el.value);
    try {
      setLoadBtn(true);
      if (isNew) {
        body["personId"] = persona.id;
        await appendUsuarioPlataforma({ body });
        showSnackBar("Persona agregada con exito.", "alert", {
          color: "success",
        });
      } else {
        await editUsuarioPlataforma({ id: user._id, body });
        showSnackBar("Persona editada con exito.", "alert", {
          color: "success",
        });
      }
      setOpenModal(false);
      setResetTable(true);
      setUser(null);
      setLoadBtn(false);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    }
  };

  const handleEditUser = (user) => {
    setUser(user);
    setOpenModal(true);
  };

  const handleOpenDeleteUser = (user) => {
    console.log(user);
    setUser(user);
    setOpenDelModal(true);
  };

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  const handleDelUser = async () => {
    try {
      setLoadBtn(false);
      await deleteUsuarioPlataforma({ id: user._id });
      showSnackBar("Persona eliminada con exito.", "alert", {
        color: "success",
      });

      setOpenDelModal(false);
      setUser(null);
      setResetTable(true);
      setLoadBtn(false);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    }
  };

  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <RemoteTable
          url={"usuario/pagination"}
          columns={columns}
          enableFilters={true}
          initialState={{
            showGlobalFilter: true,
            density: "compact",
          }}
          resetTable={resetTable}
          setResetTable={setResetTable}
          muiTableBodyCellProps={({ column }) =>
            column.id === "mrt-row-actions"
              ? {
                  sx: {
                    minWidth: "100px",
                    maxWidth: "100%",
                  },
                  align: 'center'
                }
              : {
                  style: {
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  },
                }
          }
          enableRowActions={true}
          positionGlobalFilter={"left"}
          renderRowActions={({ row }) => (
            <>
              <EditBtn onClick={() => handleEditUser(row.original)}></EditBtn>
              <DeleteBtn
                onClick={() => handleOpenDeleteUser(row.original)}
              ></DeleteBtn>
            </>
          )}
          renderToolbarInternalActions={() => (
            <Tooltip title={"Agregar usuario"}>
              <IconButton
                onClick={handleAddUser}
                color="primary"
                variant="contained"
              >
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        ></RemoteTable>
      </div>
      <UsuarioModal
        open={openModal}
        user={user}
        perfiles={perfiles}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmitUser}
      ></UsuarioModal>
      <ModalComponent
        openModal={openDelModal}
        setModal={setOpenDelModal}
        title={"Eliminar gerencia"}
        onSubmit={handleDelUser}
        maxWidth={"sm"}
        fullWidth={true}
        labelBtnSubmit={"Guardar"}
        loadBtn={loadBtn}
      >
        <Typography>{`¿Seguro de eliminar el usuario ${nombrePersona} del sistema?`}</Typography>
      </ModalComponent>
    </>
  );
}

export default UsuariosPlataforma;

import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthGuard from "utils/route-guard/AuthGuard";
import AuthPropGuard from "utils/route-guard/AuthPropGuard";

// propuestas routing
const PropuestaIndicadores = Loadable(
  lazy(() => import("views/propuesta/propuesta"))
);
const Propuesta = Loadable(lazy(() => import("views/propuesta/propuesta")));
const Propuestas = Loadable(lazy(() => import("views/propuesta/propuestas")));

const PropuestaRoutes = [
  {
    path: "/propuesta/indicadores",
    element: <PropuestaIndicadores />,
  },
  {
    path: "/propuestas",
    element: <Propuestas />,
  },
  {
    path: "/propuesta/agregar",
    element: <Propuesta />,
  },
  {
    path: "/propuesta/editar/:id",
    element: <Propuesta />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const PropRoute = {
  path: "/",
  element: (
    <AuthGuard>
      <AuthPropGuard>
        <MainLayout />
      </AuthPropGuard>
    </AuthGuard>
  ),
  children: [...PropuestaRoutes],
};

export default PropRoute;

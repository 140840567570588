import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import AutocompleteFormAsync from "ui-component/forms/AutocompleteFormAsync";
import SelectForm from "ui-component/forms/SelectForm";
import ModalComponent from "ui-component/Modal";
import axiosServices from "utils/axios";
import { object, string } from "yup";

const typeSelectOptions = [{ value: "Gerencia", label: "Gerencia" }];

const typeException = {
  Gerencia: {
    id: "Gerencia",
    label: "Gerencia",
    placeholder: "Escribe y selecciona un gerencia",
    generateOptions: async (search) => {
      const { data } = await axiosServices.post("gerencias/pagination", {
        pagination: false,
        filter: search,
      });

      const array =
        data?.docs.map((el) => {
          const { _id, nombre, sigla } = el;
          return {
            value: _id,
            label: `${sigla} - ${nombre}`,
            nombre: nombre,
          };
        }) ?? [];

      return array;
    },
  },
};

const initialValuesFormik = {
  typeSelected: "",
  valueSelected: null,
};

function GerenciaModal({ open, setOpen, handleSubmitModal }) {
  const formik = useFormik({
    initialValues: initialValuesFormik,
    validationSchema: object({
      valueSelected: object({
        value: string().required("Este campo es requerido"),
      }).required("Este campo es requerido"),
    }),
    onSubmit: async (values) => {
      handleSubmitModal(values);
      handleClose();
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("typeSelected", "Gerencia");
    }

    //eslint-disable-next-line
  }, [open]);

  const showAutocompleteAsync = useMemo(() => {
    return formik.values.typeSelected;
  }, [formik.values.typeSelected]);

  const typeId = useMemo(() => {
    return typeException[formik.values.typeSelected]?.id;
  }, [formik.values.typeSelected]);

  const typeLabel = useMemo(() => {
    return typeException[formik.values.typeSelected]?.label;
  }, [formik.values.typeSelected]);

  const typePlaceholder = useMemo(() => {
    return typeException[formik.values.typeSelected]?.placeholder;
  }, [formik.values.typeSelected]);

  const checkError = useMemo(() => {
    return formik.touched.valueSelected && Boolean(formik.errors.valueSelected);
  }, [formik.touched, formik.errors]);

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <ModalComponent
      openModal={open}
      setModal={handleClose}
      title={"Agregar contrato o gerencia"}
      onSubmit={() => {
        formik.handleSubmit();
      }}
      maxWidth={"sm"}
      fullWidth={true}
      labelBtnSubmit={"Guardar"}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12} mt={1}>
          <SelectForm
            label={"Seleccionar tipo"}
            id={"typeSelected"}
            placeholder={
              "Selecciona contrato, gerencia o unidad organizacional"
            }
            size="medium"
            value={formik.values.typeSelected}
            handleChange={(evt) => {
              let val = evt.target.value;
              formik.setFieldValue("typeSelected", val);
              formik.setFieldValue("typeValue", null);
            }}
            MenuItems={typeSelectOptions}
            formikError={{
              touched: formik.touched,
              errors: formik.errors,
            }}
          />
        </Grid>
        {showAutocompleteAsync && (
          <Grid item xs={12}>
            <AutocompleteFormAsync
              multiple={false}
              id={typeId}
              placeholder={typePlaceholder}
              getAsyncOption={
                typeException[formik.values.typeSelected]?.generateOptions
              }
              onClear={() => {
                console.log("asdf");
                formik.setFieldValue("valueSelected", null);
              }}
              value={formik.values.valueSelected}
              handleChange={(value) => {
                formik.setFieldValue("valueSelected", value);
              }}
              propTextField={{
                label: typeLabel,
                error: checkError,
                helperText: checkError && formik.errors.valueSelected,
                InputLabelProps: {
                  shrink: true,
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </ModalComponent>
  );
}

export default GerenciaModal;

"use client";

import { Add } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addPerfil, DeletePerfil, editPerfil } from "services/database/perfil";
import { getPermisos } from "services/database/permiso";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { DeleteBtn, EditBtn } from "ui-component/Buttons";
import RemoteTable from "ui-component/Table/RemoteTable";
import DeletePerfilModal from "./Modals/DeletePerfil";
import PerfilModal from "./Modals/PerfilModal";

function PerfilesPlataforma(user) {
  const dispatch = useDispatch();

  const [openAppModal, setOpenAppModal] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [permisos, setPermisos] = useState([]);
  const [perfil, setPerfil] = useState({});
  const [resetTable, setResetTable] = useState([]);

  const columns = [
    {
      accessorKey: "nombre",
      header: "Nombre",
      size: 100,
      Cell: ({ row }) => (
        <Tooltip title={row.original.nombre}>
          <Typography noWrap>{row.original.nombre}</Typography>
        </Tooltip>
      ),
    },
    {
      accessorKey: "descripcion",
      header: "Descripcion",
      Cell: ({ row }) => (
        <Tooltip title={row.original.descripcion}>
          <Typography noWrap>{row.original.descripcion}</Typography>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    const handleGetPermisos = async () => {
      const data = await getPermisos();
      setPermisos(data);
    };

    handleGetPermisos();
  }, []);

  const handleOpenModal = (perfil) => {
    setPerfil(perfil);
    setOpenAppModal(true);
  };

  const handleCloseModal = () => {
    setOpenAppModal(false);
  };

  const handleSubmit = async (values) => {
    try {
      const { isNew, ...body } = values;
      if (isNew) {
        await addPerfil({ body });
        showSnackBar("Perfil agregado con exito.", "alert", {
          color: "success",
        });
      } else {
        await editPerfil({ body, id: perfil._id });
        showSnackBar("Perfil editado con exito.", "alert", {
          color: "success",
        });
      }
      handleCloseModal(false);
      setResetTable(true);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    } finally {
      setPerfil({});
    }
  };

  const handleOpenDel = (perfil) => {
    setPerfil(perfil);
    setOpenDelModal(true);
  };

  const handleDelete = async () => {
    try {
      await DeletePerfil({ id: perfil._id });
      showSnackBar("Perfil eliminado con exito.", "alert", {
        color: "success",
      });
      setOpenDelModal(false);
      setResetTable(true);
    } catch (error) {
      showSnackBar(error.message, "alert", {
        severity: "error",
        color: "error",
      });
    } finally {
      setPerfil({});
    }
  };

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <RemoteTable
        url={"perfil/pagination"}
        columns={columns}
        resetTable={resetTable}
        setResetTable={setResetTable}
        enableFilters={true}
        initialState={{
          showGlobalFilter: true,
          density: "compact",
        }}
        positionGlobalFilter={"left"}
        enableRowActions={true}
        renderRowActions={({ row }) => (
          <>
            <EditBtn onClick={() => handleOpenModal(row.original)}></EditBtn>
            <DeleteBtn onClick={() => handleOpenDel(row.original)}></DeleteBtn>
          </>
        )}
        renderToolbarInternalActions={() => (
          <Tooltip title={"Agregar perfil"}>
            <IconButton
              onClick={() => {
                handleOpenModal({ isNew: true });
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        muiTableBodyCellProps={({ column }) =>
          column.id === "mrt-row-actions"
            ? {
                style: {
                  minWidth: "100px",
                  maxWidth: "100%",
                },
                align: 'center'
              }
            : {
                style: {
                  paddingLeft: "16px",
                  paddingRight: "16px",
                },
              }
        }
      ></RemoteTable>
      <DeletePerfilModal
        open={openDelModal}
        setOpen={setOpenDelModal}
        perfil={perfil}
        onSubmit={handleDelete}
      ></DeletePerfilModal>
      <PerfilModal
        open={openAppModal}
        handleCloseModal={handleCloseModal}
        perfil={perfil}
        handleSubmit={handleSubmit}
        permisos={permisos}
      ></PerfilModal>
    </div>
  );
}

export default PerfilesPlataforma;

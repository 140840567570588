import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export const EditBtn = ({ onClick }) => {
  return (
    <Tooltip placement="top" title="Editar" onClick={onClick}>
      <IconButton color="secondary" aria-label="delete" size="large">
        <EditTwoTone sx={{ fontSize: "1.1rem" }} />
      </IconButton>
    </Tooltip>
  );
};

export const DeleteBtn = ({ onClick }) => {
  return (
    <Tooltip placement="top" title="Eliminar" onClick={onClick}>
      <IconButton color="error" size="large">
        <DeleteTwoTone sx={{ fontSize: "1.1rem" }} />
      </IconButton>
    </Tooltip>
  );
};
